import React, { useState } from 'react';
import './style.scss';
import vetApi from '../../../services/vetApi';

const AddClinicForm = () => {
  const [clinic, setClinic] = useState({
    type: "clinic",
    name: "",
    address: "",
    description: "",
    meetingUrl: "",
    directionUrl: "",
    services: [],
    phoneNumber: "",
    email: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClinic({ ...clinic, [name]: value });
  };

  const handleServiceChange = (index, e) => {
    const { name, value } = e.target;
    const services = [...clinic.services];
    services[index][name] = value;
    setClinic({ ...clinic, services });
  };

  const addService = () => {
    setClinic({ ...clinic, services: [...clinic.services, { serviceName: "", servicePrice: "" }] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await vetApi.createPetClinic(clinic); // Replace with your actual API call
      console.log('Clinic added:', response);
    } catch (error) {
      console.error('Error adding clinic:', error);
    }
  };

  return (
    <div className='addClinicFormWrapper'>
      <h1>Add Clinic</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={clinic.name} onChange={handleInputChange} required />
        </label>
        <label>
          Address:
          <input type="text" name="address" value={clinic.address} onChange={handleInputChange} required />
        </label>
        <label>
          Description:
          <textarea name="description" value={clinic.description} onChange={handleInputChange} required />
        </label>
        <label>
          Meeting URL:
          <input type="url" name="meetingUrl" value={clinic.meetingUrl} onChange={handleInputChange} />
        </label>
        <label>
          Direction URL:
          <input type="url" name="directionUrl" value={clinic.directionUrl} onChange={handleInputChange} />
        </label>
        <label>
          Phone Number:
          <input type="tel" name="phoneNumber" value={clinic.phoneNumber} onChange={handleInputChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={clinic.email} onChange={handleInputChange} required />
        </label>
        <div className='servicesSection'>
          <h2>Services</h2>
          {clinic.services.map((service, index) => (
            <div key={index}>
              <label>
                Service Name:
                <input
                  type="text"
                  name="serviceName"
                  value={service.serviceName}
                  onChange={(e) => handleServiceChange(index, e)}
                  required
                />
              </label>
              <label>
                Service Price:
                <input
                  type="text"
                  name="servicePrice"
                  value={service.servicePrice}
                  onChange={(e) => handleServiceChange(index, e)}
                  required
                />
              </label>
            </div>
          ))}
          <button type="button" onClick={addService}>Add Another Service</button>
        </div>
        <button type="submit">Add Clinic</button>
      </form>
    </div>
  );
};

export default AddClinicForm;
