import { Box, Modal } from '@mui/material'
import EnquiryImage from '../../../Assets/enquiryImage.png'
import './style.scss'
import BookingPicker from '../BookingPicker'
import OtpController from '../../Pages/Otp'
import LoginDog from '../../../Assets/loginDog.png'

const LoginPopUp = ({open, setOpen}) => {
    const handleClose = () => {
        setOpen(false);
    }
  return (
    <div className='loginPopupWrapper'>
        <Modal
            open={open}
            className='loginPopupModal'
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        > 
        <div className='loginPopUp'> 
          <img src={LoginDog} style={{width: "100px",  height: "auto", transform: "translateY(-50px) translateX(1%) scale(1.8)" }}/>
          <h1 className='loginPopUpHeader'>Woof Woof!</h1>
          <p className='loginPopUpPara'>Login to access the app</p>
          <OtpController isPopup={true} setOpen={setOpen}/>
        </div>
      </Modal>
    </div>
  )
}

export default LoginPopUp