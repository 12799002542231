import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        fName: undefined,
        lName: undefined,
        role: undefined,
        phoneNo: undefined,
        userId: undefined,
        email: undefined
    },
    reducers: {
        loginUser: (state, action) => {
            state.fName = action.payload.fName;
            state.lName = action.payload.lName;
            state.role = action.payload.role;
            state.phoneNo = action.payload.phoneNo;
            state.userId = action.payload.userId;
            state.email = action.payload.email
        }
    }
});

export const {loginUser} = userSlice.actions
export default userSlice.reducer