import { Skeleton } from '@mui/material'
import React from 'react'
import './style.scss'

const ServiceSkeleton = () => {
  return (<div className='serviceSkeletonMainWrapper'>
            <Skeleton className='skeletonBoxMain' variant="rounded" />
            <div className='serviceSkeletonWrapper'>
                <div className='serviceSkeleton'>
                {/* <Skeleton variant="rectangular" width={210} height={60} /> */}
                    <Skeleton className='skeletonImage' variant="circular" />
                    <Skeleton className='skeletonBox' variant="rectangular" />
                    <div className='skeletonActionWrapper'>
                        <Skeleton className='skeletonButton' variant="rounded"  />
                        <Skeleton className='skeletonButton' variant="rounded"  />
                    </div>
                </div>
                <div className='serviceSkeleton'>
                    {/* <Skeleton variant="rectangular" width={210} height={60} /> */}
                    <Skeleton className='skeletonImage' variant="circular" />
                    <Skeleton className='skeletonBox' variant="rectangular" />
                    <div className='skeletonActionWrapper'>
                        <Skeleton className='skeletonButton' variant="rounded"  />
                        <Skeleton className='skeletonButton' variant="rounded"  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSkeleton