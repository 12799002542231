// services/authApi.js
import axios from 'axios';
import API_CONFIG from '../config/apiConfig';

const instance = axios.create({
  baseURL: API_CONFIG.baseUrl,
});

const bookingApi = {
  createBooking: async (booking) => {
    try {
      const response = await instance.post('/booking', booking);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default bookingApi;
