import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: "application",
    initialState:{
        notification: "",
        error: ""
    },
    reducers: {
        newNotification: (state, action) => {
            state.notification = action.payload
        },
        resetNotification: (state) => {
            state.notification = ""
        },
        newError: (state, action) => {
            state.error = action.payload
        },
        resetError: (state) => {
            state.error = ""
        }
    }
});


export const {newNotification, resetNotification, newError, resetError} = appSlice.actions;
export default appSlice.reducer;


export const sendNewNotification = (notification) => (dispatch) => {
    dispatch(newNotification(notification));

    setTimeout(() => {
        dispatch(resetNotification());
    }, 2000)
}